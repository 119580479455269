/*
* GLOBALS
*/
$minBoxHeight: rem(250px);   

// HEADER
//////////////////////////////

#header {
	position: relative;	
	min-height: rem(100px);
	padding-top: rem(30px);
	@include breakpoint(giant) {
		padding-top: rem(66px);
	}
	&:before {
		@extend .pseudoElementOverlay; 
		background: $gradientLight;
		background: linear-gradient(90deg, rgba($gradientLight,1) 0%, rgba($gradientDark,1) 100%);
		.index & {
			bottom: 65%;
		}				
	}
	.claimWrap {
		border-radius: rem(10px);
		padding: 8px;
		background-color: $light;
		.claimContainer {
			position: relative;
			border-radius: rem(10px);
			color: $light;		
			background-color: $overlayCol;
			@include responsiveImage('/images/layout/bgHeader.jpg', (tiny, large), false);
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
			.claimBox {
				display: flex;
				flex-direction: column;
				align-items: center;
				
				> div {
					display: flex;
					justify-content: center;

					> span {
						display: block;
						&:first-of-type {
							font-weight: 300;
							font-size: rem(26px);							
							@include breakpoint ( medium, max ) {
								font-size: 1rem;
							}
							@include breakpoint(medium) {
								padding-bottom: rem(5px);
							}
							@include breakpoint(giant) {
								font-size: rem(38px);
								padding-bottom: rem(10px);
							}
						}	

						&:last-of-type {
							font-weight: 700;
							font-size: rem(26px);						
							@include breakpoint ( medium, max ) {
								font-size: 1rem;
							}
							@include breakpoint(medium) {
								padding-top: rem(20px);
							}
							@include breakpoint(giant) {
								padding-top: rem(30px);
								font-size: rem(38px);
							}
						}
					}
				}

				.claimHighlightContent {
					display: flex;
					justify-content: center;
					flex-basis: 100%;
					width: 100%;

					.claimHighlight {
						font-family: $displayFont;
						font-weight: 700;
						@extend .bigFontSize;
						text-align: left;
						hyphens: none;
						display: flex;
						align-items: center;
						line-height: 130%;

						border: {
							top: rem(2px) solid rgba( $light, .8 );
							bottom: rem(2px) solid rgba( $light, .8 );
						}

						padding: {
							top: rem(2px);
							bottom: rem(2px);
						}

						margin: {
							top: rem( 10px );
							bottom: rem( 10px );
						}

						@include breakpoint ( small ) {
							max-width: 80%;

							@include breakpoint ( medium ) {
								margin: {
									top: rem( 20px );
									bottom: rem( 20px );
								}
							}
						}

						@include breakpoint ( tiny, max ) {
							font-size: rem( 16px );
						}

						span {
							padding-left: rem( 10px );
							position: relative;

							@include breakpoint (tiny) {
								padding-left: rem( 20px );

								@include breakpoint (medium) {
									padding-left: rem( 100px );

									@include breakpoint (huge) {
										padding-left: rem( 140px );
									}
								}
							}

							&:before {
								content: '';
								width: rem( 5px );
								height: 100%;
								position: absolute;
								top: 0;
								left: 0;
								background: rgba( $light, .8 );
								z-index: 10;

								@include breakpoint (tiny) {
									width: rem( 15px );

									@include breakpoint (medium) {
										width: rem( 80px );

										@include breakpoint (huge) {
											width: rem( 120px );
										}
									}
								}
							}
						}				
					}
				}
			}			
		}
	}	
}



// MAIN CONTENT
//////////////////////////////

#pageWrap {
	padding-top: $barHeight;
	@include breakpoint($break) {
		padding-top: rem(152px);
	}
}

main {
	display: block;

	p,
	ol,
	ul,
	dl,
	table {
		@extend .marginBottom;
	}

	ul:not(.unstyledList),
	dl:not(.unstyledList),
	ol:not(.unstyledList) {
		@extend .styledList;
	}

	img {
		margin-bottom:rem($baseGap*2);
	}
	.myContentBoxBg {
		background-color: $dark;
		min-height: $minBoxHeight;
		border-radius: rem(10px);
		@include breakpoint(large) {
			height: 100%;
		}		
		&.secondContentBoxBg {
			@include responsiveImage('/images/index/secondSectionBg.jpg', (tiny, large), true);
			.mietenKaufen & {
				@include responsiveImage('/images/mieten-kaufen/secondSectionBg.jpg', (tiny, large), true);
			}
			.beratung & {
				@include responsiveImage('/images/beratung/secondSectionBg.jpg', (tiny, large), true);
			}
			.unternehmen & {
				@include responsiveImage('/images/unternehmen/secondSectionBg.jpg', (tiny, large), true);
			}
		}
		&.lazyLoaded {
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
		}
	}	
}

#privacyPolicy
{
	a
	{
		display: inline-block; 
		word-break: break-all;
	}
}

// FOOTER
//////////////////////////////

#footer {
	background: $gradientDark;
	background: linear-gradient(90deg, rgba($gradientLight,1) 0%, rgba($gradientDark,1) 100%);
	.footerBox {

		@include responsiveImage('/images/layout/bgFooter.png', (tiny), true);
	
		&.lazyLoaded {
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center bottom;
		}
		.footerContent {
			padding-top: rem(30px);
			padding-bottom: rem(50px);
			@include breakpoint(tiny) {
				padding-top: rem(40px);
				padding-bottom: rem(60px);
			}
			@include breakpoint(small) {
				padding-top: rem(60px);
				padding-bottom: rem(80px);
			}
			@include breakpoint(medium) {
				padding-top: rem(80px);
				padding-bottom: rem(100px);
			}
			@include breakpoint(large) {
				padding-top: rem(90px);
				padding-bottom: rem(120px);
			}
			@include breakpoint(giant) {
				padding-top: rem(100px);
				padding-bottom: rem(130px);
			}
			@include breakpoint(full) {
				padding-top: rem(114px);
				padding-bottom: rem(180px);
			}
			span {
				display: block;
				color: $light;
				&.clientInfo {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					@include breakpoint(small) {
						flex-direction: row;
					}
					.brandPos {
						display: block;
						border-radius: rem(3px);
						padding: rem(4px);
						background-color: white;
						box-shadow: 0 0 10px 0 rgba(0, 0, 0, .6); 
						@include breakpoint(small) {
							display: inline-block;
						}						
						a.branding {
				    		img {
				    			height: auto;
								width: 60px;
								transform: scale(.75);						
				    		}	
				    	}					
					}
					span.client {
						padding-top: rem(10px);
						font-family: $displayFont;
						font-weight: 700;
						font-size: rem(20px);
						@include breakpoint(small) {
							padding-top: 0;
							padding-left: rem(20px);
						}
						@include breakpoint(medium) {
							font-size: rem(24px);
						}
						@include breakpoint(large) {
							font-size: rem(27px);
						}
						@include breakpoint(full) {
							font-size: rem(30px);
							padding-left: rem(30px);
						}				
					}
				}
				&.addressDetails {
					span {
						font-weight: 300;						
						@include breakpoint(tiny) {
							display: inline-block;
						}
						@include breakpoint(medium) {
							font-size: rem(18px);
						}
						&:nth-child(1) {
							@include breakpoint(tiny) {
								padding-right: rem(10px);
								&:after {
									content: ',';
								}
							}
						}
						&:nth-child(2) {
						}
					}
					
				}
				&.phone {
					font-weight: 700;
					@include breakpoint(medium) {
						font-size: rem(18px);
					}
					span {
						display: inline-block;
					}
				}
				&.fax {
					@include breakpoint(medium) {
						font-size: rem(18px);
					}
				}
				&.mail {
					a, span {
						color: $light;
						text-decoration: none;
						@include breakpoint(medium) {
							font-size: rem(18px);
						}
						&:hover {
							color: $secondary;
						}
					}
					
				}
				&.appointmentNote {
					font-family: $displayFont;
					font-weight: 700;
					font-size: rem(18px);
					padding-bottom: rem(20px);
					@include breakpoint(medium) {
						font-size: rem(20px);
					}
					@include breakpoint(large) {
						font-size: rem(22px);
					}
					@include breakpoint(full) {
						font-size: rem(24px);
					}
				}											
			}
			div.centerMe {
				@include breakpoint(tiny) {
					display: flex;
					justify-content: center;
				}
				.btn {
					pointer-events: none;
					background-color: rgba($light, .49);
					max-width: rem(600px);

					dl.openingHours {
						font-size: rem(14px);
						font-weight: 700;
						color: $primary;
						@include breakpoint(tiny) {
							display: inline-flex;
							justify-content: center;
						}			
						@include breakpoint(medium) {
							font-size: rem(18px);
						}		
						dt {
							&:nth-child(1),
							&:nth-child(3),
							&:nth-child(5) {								
								@include breakpoint(tiny) {
									padding-right: rem(5px);
								}
								
							}
							&:nth-child(3),
							&:nth-child(5) {
								padding-top: rem(10px);
								@include breakpoint(tiny) {
									padding-top: 0;
								}
							}
						}
						dd {
							&:nth-child(2),
							&:nth-child(4) {
								display: block;
								border-bottom: 1px solid rgba($primary, .2);
								@include breakpoint(tiny) {
									border-bottom: none;
								}
								&:after {
									@include breakpoint(tiny) {
										content: ',';
										padding-right: rem(10px);
									}
								}
							}
						}						
					}
				}	
			}
		}
	}
	.naviAddContainer {
		@include breakpoint(tiny,max) {
			padding-bottom: 60px !important;
		}
	}
}

.WidgetBanner {
	@include breakpoint(large,max) {
		bottom: 80px !important;
	}
}

.privacyControlBadge {
	bottom: 80px;
}