// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: giant; // Breakpoint – switch to Desktop version

// NAVIGATION
//////////////////////////////////////////////

$navBg: $gradientLight; // Navigation Background
$navColor: $dark; // Navigation Link Color

@mixin navButton {
	// Default Link Styles
	padding: 1em rem($baseGap);
	text-decoration: none;
	font-size: rem(16px);
	color: $light;
	transition: all $baseTransitionTime;
}
%buttonHover {
	// Link Hover
	// color: $linkCol;
	background-color: lighten($primary, 15%);
}
%buttonActive {
	// Link Active
	color: $light;
	background-color: $secondary;
	// padding: rem(20px) rem(25px);
}
%prevIcon {
	// Icon to previus Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);
	transform: rotate(180deg);

	// @include breakpoint($break) {
	// 	transform:rotate(90deg);
	// }
}
%nextIcon {
	// Icon to next Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);

	@include breakpoint($break) {
		transform:rotate(90deg);
	}
}
$closeIcon: map-get($iconMap, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$barHeight: 50px; // height (in px)
$barPos: top; // position (top, bottom)
$barHide: true; // if bar hides on scroll (true, false)
$barPadding: 46px;

$barBg: $dark; // background-color
$barColor: $light; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

// Navigation Styles
////
@include pageNavi {
	background-color: $navBg;
	z-index: 1001;

	nav {
		@include ul {
			&.navi {
				li {
					@include navButtons {
						@include navButton;
					}
					@include hasSub {
						@include sub {
							background: $navBg;

							&:before {
								padding: 1em rem($baseGap);
								background-color: darken($navBg, 6%);
							}
						}
					}
					@include navBack {
					}
				}
			}
		}
		span { // BACK-Button
			color: $secondary;
			padding-left: rem(10px);
			padding-right: rem(10px);
		}
	}
}


/// ***** MOBILE NAVI ***** ///

@include breakpoint($break, max) {

	#navigation {
		// background-color: $medium;
		transition: all $baseTransitionTime;
		overflow: hidden;
    	overflow-y: scroll;

		@include breakpoint(tiny) {
			max-width: 70vw;
		}
		@include breakpoint(small) {
			max-width: 60vw;
		}
		
		.nav {
			.naviSetup {
				margin-left: 0 !important;
				padding-left: 0 !important;
				margin-right: 0 !important;
				padding-right: 0 !important;
				display: flex;
				flex-direction: column-reverse;			
				.branding {
			    	display: none;
			    }
			    nav {
			    	 ul {
			    	 	text-align: right;
						&.navi {
							li {								
								a {
									color: $primary;
									// padding-right: rem(42px);
									padding-top: 1em;
									padding-bottom: 1em;
									transition: all $baseTransitionTime;
									&.active {
										color: $light;
										padding-top: 1em;
										padding-bottom: 1em;
									}
									&:hover {
										background: $gradientDark;
										background: linear-gradient(90deg, rgba($gradientLight,1) 0%, rgba($gradientDark,1) 100%);
									}
								}
								&.hasSub {
									span {
										padding-top: 1em;
										padding-bottom: 1em;
										color: $primary;
									}
									.sub {	
										transition: all $baseTransitionTime;
									}
									&:hover {
										background-color: $medium;
									}
								}
								&.navBack {
									// padding-right: rem(42px);
									padding-top: 1em;
									padding-bottom: 1em;
									color: $primary;
									&:hover {
										color: lighten($primary,5%);
									}
								}
							}
						}
					}
			    } 
			    .contactDetails {
			    	font-size: rem(14px);
			    	line-height: rem(24px);
			    	padding: rem(20px) rem(35px) rem(20px) rem(35px);
			    	text-align: right;  
			    	background: $gradientLight;
					background: linear-gradient(90deg, rgba($gradientLight,1) 0%, rgba($gradientDark,1) 100%);
			    	a, span {
		    			&.phone, &.maillink, &.email {
		    				display: block;
		    				text-decoration: none;
		    				color: $secondary;
		    			}
		    		}
		    		.phone {
		    			font-weight: bold;
		    		}
			    }	    	
	    		.socialLinkBox {
			        display: none;
				}	
			}			
		}
    }
}
/// ***** DESKTOP NAVI ***** /// 
@include breakpoint($break) {
	@include desktopVersionFunctionality;

	#navigation {
		color: $light;
		background-color: $primary;
		.nav {							
			padding-top: rem(20px);
			transition: padding-top $baseTransitionTime;
			.naviSetup {
				padding-top: rem(26px);
				padding-bottom: $barPadding;
				display: flex;
				justify-content: space-between;
				transition: padding-top $baseTransitionTime, padding-bottom $baseTransitionTime;
				.brandPos {
					border-radius: rem(3px);
					padding: rem(4px);
					background-color: white;
					box-shadow: $baseShadow; 
					transform: scale(1) translateX(rem(-2px))translateY(rem(0px));
					
					transition: all $baseTransitionTime;
					@include breakpoint(huge) {
						transform: scale(1.5) translateX(rem(0px)) translateY(rem(0px));
					}
					@include breakpoint(full) {
						transform: scale(2) translateX(rem(10px));
					}
					@include breakpoint(fullHD) {
						transform: scale(3) translateY(rem(9px));
						transition: all $baseTransitionTime;
						
					}
					.scroll & { 
						box-shadow: none; 
					    transform: scale(1) translateX(rem(-2px))translateY(rem(0px));
						@include breakpoint(fullHD) {							
					      	transform: scale(1.5) translateY(rem(10px));  
					      	box-shadow: $baseShadow;  
						}
			    	}
					a.branding {
			    		img {
			    			height: auto;
							width: 50px;
							transform: scale(.75);						
			    		}	
			    	}					
				}							    
				nav {
					ul {
						&.navi {
							li {
								a {
									padding: rem(15px) rem(25px);
									border-radius: rem(10px);
								}
								&.hasSub {
									padding: rem(15px) rem(25px);
									border-radius: rem(5px);
									.sub {								
										text-align: left;
										padding: rem(10px);
										box-shadow: $baseShadow;
										a {
											padding: rem(10px);
										}
									}
								}
								&.navBack {
									background-color: white;
									padding: 0;
								}
							}
						}
					}
				}	
				.contactDetails {
					span {
		    			&.phoneLink {
		    				a, span {
		    					font-weight: 700;
		    					font-size: rem(13px);
		    					text-decoration: none;
		    					@include breakpoint(huge) {
			    					font-size: rem($baseFontSize);
			    				}
		    				}

		    			}
		    			&.mailLink {
		    				a, span {
		    					padding-right: rem(30px);
			    				font-size: rem(13px);
			    				color: $naviCol;
			    				text-decoration: none;
			    				@include breakpoint(huge) {
			    					font-size: rem(14px);
			    				}
			    				&:hover {
			    					color: lighten($primary, 50%);
			    				}
		    				}		    				
		    			}
		    		}
				}	    		
	    		.socialLinkBox {
	    			display: inline-flex;
			        align-items: center;
			        justify-content: flex-end;
	    			a {
	    				&.facebookLink, &.xingLink {
	    					margin-left: rem(10px);
							width: rem(25px); 
							height: rem(25px); 
							svg {
								width: 100%; 
								height: 100%;  
								&.facebookIcon {
									transition: all $baseTransitionTime;									
									fill: $light;	
									&:hover {	
										fill: $medium;	
										box-shadow: $baseShadowLight;
										border-radius: rem(25px);	
									}						
								}
							}
	    				}
	    				&.xingLink {
	    					display: flex;
				        	align-items: center;
				        	height: rem(25px);
				        	img {
				        		height: rem(25px);
				        		width: rem(25px);
				        		padding: 0;
				        		margin: 0;
				        		transform: translateY(rem(-2px));
				        	}
	    					&:hover {
	    						box-shadow: $baseShadowLight;
	    					}
	    				}
	    			}
	    		}
	    		
			} 
			.scroll & {
				padding-top: 0;
				.naviSetup {
					padding-top: rem(0);
					padding-bottom: rem(0);
					.brandPos {

					}
				}					
			} 			  
		}
	}
}



// Quickbar Styles
////
#quickbar {
	background-color: $primary;
	color: $barColor;
	display: flex;
	// position: relative;
	justify-content: space-between;
	align-items: center;
	padding: rem($baseGap);
	z-index: 1000;
	
	@include breakpoint($break) {
		display: none;
	}
	@include breakpoint($break, max) {
		box-shadow: $baseShadow;
	}
	.branding {
		height: rem(42px);
		padding: rem(6px);
		border-radius: rem(4px);
		background: white;
		transform: scale(1.5);
		transform-origin: top left;
		transition: transform 300ms;
		.scroll & {
			transform: scale(1);
		}
		img {
			width: auto;
			height: 100%;
		}
	}
	.positionizer {
		display: flex;
		.socialLinkBox {
			padding-right: rem(30px);
	        display: inline-flex;
	        align-items: center;
	        justify-content: center;
	        left: rem(28px);
	        top: rem(5px);
	        .xingLink {
	        	display: flex;
	        	align-items: center;
	        	height: rem(25px);
	        	img {
	        		height: rem(25px);
	        		width: rem(25px);
	        		padding: 0;
	        		margin: 0;
	        		transform: translateY(rem(-2px));
	        	}
	        	&:hover {
        			box-shadow: 0 0 5px 0 rgba(255,255,255,.8);
        		}
	        }
	        .facebookLink {
				margin-left: rem(10px);
				width: rem(25px); 
				height: rem(25px); 
				.facebookIcon {
					width: 100%; 
					height: 100%;  
				}
				svg {
					&.facebookIcon {
						width: 100%; 
						height: 100%;  
						fill: $naviCol;							
					}
				}
				&:hover {
					svg {
						&.facebookIcon {
							fill: $medium;
							box-shadow: 0 0 5px 0 rgba(255,255,255,.8);
							border-radius: rem(25px);							
						}
					}
				}
			}

			
		}
	}
	
}

//////////////////////////////////////////////
// naviAdd
//////////////////////////////////////////////
.naviAddContainer {
	background: $primary;
}
.naviAdd {
    padding: rem(25px) 0 0 0;
    font-size: rem(15px);
    @include breakpoint(large) {
    	display: flex;
        justify-content: flex-start;    
        padding: rem(50px) 0 rem(50px) rem(30px);
    }
    @include breakpoint(fullHD) { 
        padding: rem(50px) 0;
    }
    li {
    	text-align: center;
    	padding: rem(10px) 0;
    	@include breakpoint(large) {
    		padding: 0 rem(25px);
	        border-right: 1px solid $border;
	        line-height: 100%;	        
    	}
    	
        &:first-child {
        	padding-left: 0;
        }
        &:last-child {
        	border-right: none;
        	padding-bottom: rem(30px);
        	@include breakpoint(large) {
        		padding: 0 0 0 rem(25px);
        	}
        };
        @include breakpoint(small) {          
            &:first-child:before {
                display: none;
            }
        }
        a {
        	text-decoration: none;
            color:$light;
            display: inline-block;  
            position: relative;
            &.active, &:hover  {
                color: $medium;              
            }
            &.active {
            	color: $activeCol;            	
            }
        }
    }
}