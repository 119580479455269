* {
  margin: 0;
  padding: 0;

  &,
  &:before,
  &:after {
    box-sizing: inherit;
  }
}

::selection {
}
::-moz-selection {
}

// DO NOT set font-size and line-height here!
// Adjust $baseFontSize and $baseLineHeight in config
html {
  background: $light;
  box-sizing: border-box;
  color: $dark;
  font-family: $mainFont;
  font-size: 100% * ($baseFontSize / 16px);
  hyphens: manual;
  line-height: rem($baseLineHeight);
  scroll-behavior: smooth;

  /* disable text resize in landscape. e.g. on iphone */
  text-size-adjust: none;
}

body {
  line-height: rem($baseLineHeight);
  opacity: 1 !important;
}

iframe,
[data-iframe] {
  border: none;
  width: 100%;
}

address {
  font-style:normal;
}

/**
 * Headlines
 */

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  display: block;
  text-rendering: optimizeLegibility;
  hyphens: auto;
  // line-height:130%;
  line-height: 140%;
  font-family: $displayFont;
  font-weight: 700;
  color: $secondary;
  text-transform: uppercase;
  margin-bottom: 1.5em;

  /* Use this to let headlines break in Chrome Desktop
  body.platformWindows.chrome &, body.platformLinux.chrome & {
    word-break: break-all;
  }
  */
  span {
    display: block;
    font-size: .7em;
    font-weight: normal;
    text-transform: none;
    color: $dark;
    opacity: .9;
  }
}

h1,
.h1 {    
  font-size: calc(26px + (46 - 26) * ((100vw - 320px) / (1600 - 320)));
  font-size: clamp(($h1Size)*.6, 4vw, $h1Size);
}

h2,
.h2 {  
  font-size: calc(21px + (34 - 21) * ((100vw - 320px) / (1600 - 320)));
  font-size: clamp(($h2Size)*.6, 4vw, $h2Size);
}

h3,
.h3 {
  font-size: calc(19px + (32 - 19) * ((100vw - 320px) / (1600 - 320)));
  font-size: clamp(($h3Size)*.6, 4vw, $h3Size);
}

h4,
.h4 {
  font-size: calc(18px + (30 - 18) * ((100vw - 320px) / (1600 - 320)));
  font-size: clamp(($h4Size)*.6, 4vw, $h4Size);
}

h5,
.h5 {
  font-size: calc(17px + (28 - 17) * ((100vw - 320px) / (1600 - 320)));
  font-size: clamp(($h5Size)*.75, 4vw, $h5Size);
}

h6,
.h6 {
  font-size: calc(16px + (26 - 16) * ((100vw - 320px) / (1600 - 320)));
  font-size: clamp(($h6Size)*.75, 4vw, $h6Size);
}

.bigFontSize {
  font-family: $displayFont;
  font-weight: 500;
  text-transform: uppercase;
  hyphens: auto;
  line-height: 130%;
  font-size: calc(25px + (56 - 25) * ((100vw - 320px) / (1600 - 320)));
  font-size: clamp(($bigFontSize)*.45, 4vw, $bigFontSize);
}
.subBigFontSize {
  font-family: $mainFont;
  font-weight: 300;
  hyphens: auto;
  line-height: 130%;
  font-size: calc(19px + (38 - 19) * ((100vw - 320px) / (1600 - 320)));
  font-size: clamp(($subBigFontSize)*.5, 4vw, $subBigFontSize);
}

/**
 * Links
 */
a {
  color: $linkCol;
  word-wrap: break-word;
  text-decoration: underline;
  transition: color $baseTransitionTime;

  &:hover {
    color: $secondary;
  }
  &:focus {
    color: darken($secondary, 50%);
  }
  &:active {
    color: darken($secondary, 30%);
  }

  img {
    border: none;
  }

  &[href^="tel"] {
    color: inherit;
    text-decoration: none;
  }
}

a:not(.btn):focus,
[tabindex]:focus {
  outline: $outline;
  outline-offset: rem(5px);
}

hr,
.hr {
  display: flex;
  background: $medium;
  border: none;
  clear: both;
  height: rem(1px);
  margin: 1rem 0;
  flex-basis: 100%;
}

ul,
ol {
  list-style: none;

  &.styledList {
    li {
      padding-left: rem(15px);
      margin-bottom: rem(7px);

      &:before {
        @extend .icon;
        @extend .icon-angle-right;
        text-indent: rem(-15px);
      }
    }

    ul {
      margin-left: 1rem;
      margin-bottom: 0;
    }
  }
}

dl {
  &.styledList {
    @include pie-clearfix;

    dt,
    dd {
      display: block;
      background: rgba($medium, 0.8);
      padding: rem(15px);
      border-left: rem(5px) solid $medium;
    }

    dt {
      padding-bottom: 0;
      font-weight: bold;
    }

    dd {
      padding-top: 0;

      + dt {
        margin-top: rem(10px);
      }

      + dd {
        margin-top: rem(-15px);
      }
    }
  }

  &.floatList {
    @include pie-clearfix;

    dt,
    dd {
      display: block;
      vertical-align: top;
      float: left;
    }

    dt {
      padding-right: 0.5rem;
      width: 40%;
      clear: left;
    }

    dd {
      width: 60%;
    }
  }
}

ol {
  &.styledList
  {
    counter-reset: item;
  
    li {
      counter-increment: item;
  
      &:before {
        content: counters(item, ".") ". ";
      }
    }
  
    ol {
      margin-bottom: 0;
  
      li {
        &:before {
          content: counters(item, ".") " ";
          margin-right: rem(5px);
        }
      }
    }
  }
}

/*
 * responsive images
 */

img {
  vertical-align: bottom;
  float: none;
  height: auto;
  max-width: 100%;
  width: 100%;

  &[src^='http://cms.'], &[src^='https://cms.'] {
    max-width: none;
    width: auto;
  }

  [data-rel] &,
  .lightbox-image & {
    margin-bottom: 0;
  }

  &[data-src] {
    @extend %lazyloading;
  }
}

/**
 * Tables
 */
main {
  table {
    display: table;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    text-align: left;
    font-size: rem($baseFontSize);

    @include breakpoint(small, max) {
      overflow-x: auto;
      display: block;
    }

    caption {
      text-align: left;
      padding: rem($baseGap) rem($baseGap) rem(25px);
      display: table-caption;
      font-weight: bold;
      font-size: 1.2rem;
    }

    thead {
      border-collapse: collapse;
      display: table-header-group;
    }

    tbody {
      width: 100%;
      overflow-x: scroll;
      display: table-row-group;

      tr {
        padding: rem($baseGap) 0;

        &:not(:last-child) {
          border-bottom: rem(1px) solid $medium;
        }
      }
    }

    tr {
      display: table-row;
    }

    th,
    td {
      padding: rem($baseGap);
      display: table-cell;
      vertical-align: top;
    }

    @include breakpoint(small, max) {
      td {
        min-width: 50vw;
      }
    }
  }
}