// Use this for default Icomoon

@include fontFaceV2(
 $fontName: "icomoon",
 $fileName: "icomoon",
 $types: (woff, ttf, svg, eot),
 $style: normal,
);

@include fontFaceV2( 
	$fontName: "spartanMB", 
	$fileName: "spartanMB", 
	$weights: ("300", "400", "700"), 
	$types: (woff, woff2, otf)
);

@include fontFaceV2(  
	$fontName: "merriweather", 
	$fileName: "merriweather",    
	$weights: ("300", "400", "700"),   
	$types: (woff, woff2, ttf)
);