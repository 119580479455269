

// Use this file for your custom mixins/functions
//MIXINS
@mixin section {
  padding-top:rem(30px);
  padding-bottom:rem(30px);
  @include breakpoint(small) {
    padding-top:rem(50px);
    padding-bottom:rem(50px);  
  }
  @include breakpoint(medium) {
    padding-top:rem(80px);
    padding-bottom:rem(80px);
  }
  @include breakpoint(huge) {
    padding-top:rem(120px);
    padding-bottom:rem(120px);
  }
  @include breakpoint(full) {
    padding-top:rem(150px);
    padding-bottom:rem(150px);
  }
  @media only screen and (max-height: 1000px) {
    padding-top:rem(100px);
    padding-bottom:rem(100px);
  }
  @media only screen and (max-height: 700px) {
  padding-top:rem(60px);
    padding-bottom:rem(60px);
  }
  @media only screen and (max-height: 500px) {
  padding-top:rem(30px);
    padding-bottom:rem(30px);
  }
}
@mixin sectionSmall {
  padding-top:rem(30px);
  padding-bottom:rem(30px);
  @include breakpoint(small) {
    padding-top:rem(40px);
    padding-bottom:rem(40px);  
  }
  @include breakpoint(medium) {
    padding-top:rem(60px);
    padding-bottom:rem(60px);
  }
  @media only screen and (max-height: 700px) {
  padding-top:rem(40px);
    padding-bottom:rem(40px);
  }
  @media only screen and (max-height: 500px) {
  padding-top:rem(30px);
    padding-bottom:rem(30px);
  }
}
@mixin sectionVerySmall {
  padding-top:rem(20px); 
  padding-bottom:rem(20px);
  @include breakpoint(small) {
    padding-top:rem(30px);
    padding-bottom:rem(30px);  
  }
  @include breakpoint(medium) {
    padding-top:rem(40px);
    padding-bottom:rem(40px);
  }
  @include breakpoint(huge) {
    padding-top:rem(60px);
    padding-bottom:rem(60px);
  }
  @media only screen and (max-height: 700px) {
    padding-top:rem(30px);
    padding-bottom:rem(30px);
  }
  @media only screen and (max-height: 500px) {
    padding-top:rem(20px);
    padding-bottom:rem(20px);
  }
}
@mixin sectionLarge {
  padding-top:rem(30px);
  padding-bottom:rem(30px);
  @include breakpoint(small) {
    padding-top:rem(70px);
    padding-bottom:rem(70px);  
  }
  @include breakpoint(medium) {
    padding-top:rem(120px);
    padding-bottom:rem(120px);
  }
  @include breakpoint(huge) {
    padding-top:rem(150px);
    padding-bottom:rem(150px);
  }
  @include breakpoint(full) {
    padding-top:rem(180px);
    padding-bottom:rem(180px);
  }
  @media only screen and (max-height: 1000px) {
  padding-top:rem(120px);
    padding-bottom:rem(120px);
  }
  @media only screen and (max-height: 700px) {
  padding-top:rem(60px);
    padding-bottom:rem(60px);
  }
  @media only screen and (max-height: 500px) {
  padding-top:rem(30px);
    padding-bottom:rem(30px);
  }
}
// SECTION
/////////////////////////////
.section {
  @include section;
}
.sectionVerySmall {
  @include sectionVerySmall;
}
.sectionSmall {
  @include sectionSmall;
}
.sectionLarge {
  @include sectionLarge;
}
.section, 
.sectionSmall, 
.sectionLarge, 
.sectionVerySmall {
  position: relative;
  // &:before {
  //   content: "";
  //   background-color: $light;
  //   position: absolute;
  //   top: 0;
  //   bottom: 0;
  //   left: 0;
  //   right: 0;
  //   z-index: -1;
  // }
  &.sectionColor {
    background: $dark;
    color:$light;
    a {
      color:$light;
      transition: 300ms opacity;
      &:hover {
        opacity: .6;
      }
    }
  }
  &.sectionMedium {
    background: $medium;
  }
 // &.sectionPattern {
 //   @include responsiveImage('layout/bgSection.jpg', (tiny, medium), true);
 //   background-size: cover;
  //}
  &.noGapTop {
    padding-top: 0;
  }
  &.noGapBottom {
    padding-bottom: 0;
  }
}

// HELPERS
.shadow {
  box-shadow: $baseShadow
}
.noWrap {
  white-space: nowrap;
}
.noMarg {
  margin: 0;
} 

//GLOBALS

hr, .hr {
  background: $border;
  margin: 3rem 0;
  &.hrReduced {
    margin: 0 !important;
    // background: $hrCol;
  }
}
dl {
  &.styledList {
    dt,
    dd {
      background: rgba($dark, .05);
    }
  }
} 

// MAIN
small {
  display: block;
  font-size: rem(13px);
  opacity: .6;
  font-style: italic;
}

// Form styles
form.default {
  fieldset {
    margin-bottom: 0!important;
  }
}

// Mitwachsende Box für .col
body:not(.cmsBackend) .contentBox {
    // z-index: 1;
    position: relative;
    padding:rem($baseGap*2); 
    margin:rem($baseGap*2) 0;
    @include breakpoint(large) {
      padding:rem($baseGap*3) rem($baseGap*4);
    }
    > *:last-child {
      margin-bottom: 0;
    }
    &:before {
        content:'';
        background: $light;
        position: absolute;
        top:0;
        left:0;
        right:0;
        margin:auto;
        width: calc(100% - #{rem($baseGap*2)}); 
        height: 100%;
        z-index: -1;
        box-shadow: $baseShadow;
    }
}